import styled from '@emotion/styled'
import { minWidth, responsiveColumn } from 'src/themes'

const CourseCurriculum = styled.div<{
  noImage?: boolean;
  noSwapping?: boolean;
}>`
	display: flex;
	gap: 2rem;
	width: 100%;
	margin-bottom: ${(props) => (props.noImage ? '1rem' : '4rem')};

	${responsiveColumn};

	> div:first-of-type {
		height: ${(props) => (props.noImage ? 'none' : '400px')};
	}

	> div:nth-child(2) {
		flex: 1;
	}

	${minWidth[2]} {
		&:nth-child(odd) {
			> div:first-of-type {
				order: ${props => props.noSwapping ? '0' : '1'};
			}
		}
	}

	.logos {
		display: flex;
		gap: 1rem;
	}
`

export default CourseCurriculum
