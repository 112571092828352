import { css } from '@emotion/react'
import React from 'react'

function Divider() {
  return (
    <div css={css`
      height: 2px;
      color: #fff;
    `}></div>
  )
}

export default Divider