import React, { PropsWithChildren } from 'react'
import Container from '@components/Container'
import SEO from '@components/SEO'
import { useTranslation } from 'react-i18next'
import { MapCoursePageFragmentFragment } from '@graphql-types'
import CourseMode from '@components/CourseMode'
import { css } from '@emotion/react'

export type Course = NonNullable<MapCoursePageFragmentFragment['course']>

export type SpecialEvent = NonNullable<
  MapCoursePageFragmentFragment['openDayEvent']
>

const SMALayout = ({
  course,
  children,
}: PropsWithChildren<{
  course: Course
}>) => {
  const { t } = useTranslation()
  return (
    <Container css={css`
      max-width: none;;
		  padding: 0 1rem;
    `}>
      <SEO title={t('courses:sma.title')} keywords={[]} />
      <CourseMode mode='self-paced' />
      <h1>{course!.name}</h1>
      {children}
    </Container>
  )
}

export default SMALayout
