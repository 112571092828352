import React, { PropsWithChildren } from 'react'
import Container from '@components/Container'
import SEO from '@components/SEO'
import { useTranslation } from 'react-i18next'
import { MapCoursePageFragmentFragment } from '@graphql-types'
import CourseMode from '@components/CourseMode'
import { css } from '@emotion/react'
import ReactMarkdown from 'react-markdown'

export type Course = NonNullable<MapCoursePageFragmentFragment['course']>

export type SpecialEvent = NonNullable<
  MapCoursePageFragmentFragment['openDayEvent']
>

const Card = (props: {
  title: string
  description: string
  icon: any
  width?: number
}) => {
  console.log(props.icon)
  return (
    <div
      css={css`
				width: ${props.width ?? 100}%;
        display: flex;
        align-items: flex-start;

        > img {
          height: 30px;
          margin-right: 1rem;
        }
			`}>
      <img src={props.icon.default} alt="" />

      <div>
        <p
          css={css`
					display: flex;
					align-items: center;
					font-weight: 900;
          margin: 0;

				`}>
          {props.title}
        </p>

        <ReactMarkdown children={props.description} />
      </div>
    </div>
  )
}

export default Card
