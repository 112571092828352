import { css, useTheme } from '@emotion/react'
import moment from 'moment'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type {
  Course,
  SpecialEvent,
} from '@pages/{Country.name}/courses/MAP/_layout'
import { responsiveColumn } from 'src/themes'
import { Button } from './Button'
import { ButtonA } from './ButtonA'
import { ContactForm } from './ContactForm'
import { FormContext } from './Layout'

const CourseButtons = (props: {
  course: Pick<Course, 'zohoCode' | 'code' | 'name' | 'leaflet_available'>
  openDay?: SpecialEvent
  trial?: SpecialEvent
  vertical?: boolean
}) => {
  const { t } = useTranslation()
  const { showForm, hideForm } = useContext(FormContext)

  const escFunction = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        hideForm()
      }
    },
    [hideForm]
  )

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [])

  return (
    <div
      css={css`
				display: flex;
				gap: 1rem;

				${props.vertical ? 'flex-direction: column' : responsiveColumn};
			`}>
      {props.course.leaflet_available && (
        <Button
          onClick={() => {
            showForm(
              props.course.zohoCode!,
              props.course.code,
              props.course.name,
              'request_syllabus'
            )
          }}>
          {t('courses:common.request_syllabus')}
        </Button>
      )}
      {props.openDay && (
        <ButtonA href={props.openDay.url!} target='_blank'>
          {t('courses:common.open_day')}
          <br />
          {moment(props.openDay?.start?.utc).format('L')}
        </ButtonA>
      )}
      {props.trial && (
        <ButtonA href={props.trial.url!} target='_blank'>
          {t('courses:common.trial_lesson')}
          <br />
          {moment(props.trial?.start?.utc).format('L')}
        </ButtonA>
      )}
      <Button
        onClick={() => {
          location.href = 'https://calendly.com/tecky/admission';
        }}>
        {t('courses:common.apply_now')}
      </Button>
    </div>
  )
}

export default CourseButtons
